import React from 'react';
import Layout from '../components/Layout';
import keratiiniEnnen from '../images/bg/slg-ennen.jpg';
import keratiiniJalkeen from '../images/bg/slg-jalkeen.jpg';
import letitysKuva from '../images/bg/letitys.jpg';
import miestenParturiKuva from '../images/bg/parturi-slg.jpg';
import miestenKampaus from '../images/bg/kampaus-miehet.jpg';
import naistenKampaus from '../images/bg/kampaus-work.jpg';
import naistenKampaus2 from '../images/bg/slg-km2.jpg';
import varjaysJaLeikkaus from '../images/bg/varjays-ja-leikkaus.jpg';

const Gallery = () => {
    return (
        <Layout>
            <section className="hero" data-overlay="5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h1>Galleria</h1>
                        </div>
                    </div>
                </div>
                <div className="herobg heroimage5"></div>
            </section>
            <section className="content-block box-desc  section-padding pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="mb-30">Tekemämme Kamapukset</h2>
                            <p>
                                Alla näette muutamia kampauksia mitä
                                ammattilasemme ovat aina valmiita tekemään
                                teille toiviedenne muikaisesti.
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="mb-15">
                                        Keratiinisuoristus
                                    </h2>
                                    <p>
                                        Keratiinisuoristus suoristaa ja poistaa
                                        aaltomaiset ja erilaiset kiharat
                                        pitkäkestoisesti pois kemiallisilla
                                        käsittelyillä, jolloin saat suorat
                                        hiukset ilman päivittäistä suoritusta
                                        fööneillä/ suoristusraudoilla. Aineet
                                        joita käytetään ovat hellävaraisempia
                                        kuin ennen jotka antavat näin tuloksen
                                        4-6kk joka tarkoittaa että yhdellä
                                        kerralla saadaan hyvä lopputulos,
                                        jatkuvalla käytöllä voidaan suoristaa
                                        hiuksen jopa lopullisesti.
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={keratiiniEnnen}
                                                alt="Salon Looking Good, Keratiinisuoristus ennen"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>Salon LookingGood, 2019</span>{' '}
                                            - Keratiinisuoristus ennen
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={keratiiniJalkeen}
                                                alt="Salon Looking Good, Keratiinisuoristus jälkeen"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>Salon LookingGood, 2019</span>{' '}
                                            - Keratiinisuoristus jälkeen
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="proj-container p-img section-padding pt-0">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-12 text-center mt-3">
                                    <h3>Muut kampaukset</h3>
                                </div>
                                <div className="col-md-4">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={naistenKampaus2}
                                                alt="Salon Looking Good, kampaus"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>Salon LookingGood, 2019</span>{' '}
                                            - Naisten kampaus
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={miestenKampaus}
                                                alt="Salon Looking Good - Miesten kampaus"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>
                                                Salon Looking Good, 2019
                                            </span>{' '}
                                            - Miesten parturi
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={naistenKampaus}
                                                alt="Salon Looking Good - Naisten kamapaus"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>
                                                Salon Looking Good, 2019
                                            </span>{' '}
                                            - Kampaamopalvelut
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={miestenParturiKuva}
                                                alt="Salon Looking Good - Parturi palvelut"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>
                                                Salon Looking Good, 2019
                                            </span>{' '}
                                            - Miesten parturi
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={letitysKuva}
                                                alt="Salon Looking Good - Kampaukset letitys"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>
                                                Salon Looking Good, 2019
                                            </span>{' '}
                                            - Naisten letitys
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="box-im">
                                        <div
                                            className="image-zoom"
                                            data-dsn-grid="parallax"
                                            data-dsn-grid-move="20"
                                        >
                                            <img
                                                src={varjaysJaLeikkaus}
                                                alt="Salon Looking Good - Kampaamo palvelut"
                                            />
                                        </div>
                                        <div className="caption">
                                            <span>
                                                Salon Looking Good, 2019
                                            </span>{' '}
                                            - Värjäys ja leikkaus Naiset
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Gallery;
